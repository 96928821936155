import { makeAutoObservable } from "mobx";

const userStore = makeAutoObservable({
  token: "",
  tenant: "",
  nickName: "",
  userId: "",
  roles: [] as string[],

  SET_USERID(userId: string) {
    this.userId = userId;
  },
  SET_TOEKN(token: string) {
    this.token = token;
  },
  SET_TENANT(tenant: string) {
    this.tenant = tenant;
  },
  SET_NICKNAME(nickName: string) {
    this.nickName = nickName;
  },
  SET_ROLES(roles: string[]) {
    this.roles = roles
  },
  SET(data: any) {
    Object.assign(this, data);
  },
  async FETCH() {
    // const resData = await getInfo();
    // this.account = resData.user.imUid;
  },
  CLEAR() {
    // this.token = "";
    // this.account = "";
  },
});

export { userStore };
