import http from "@/utils/http";

/**
 * 流程定义分类
 */
export const userListByRoleId = async (data: any) => {
  const res = await http.get(`/mobile/sys/user/list`, {
    params: data,
  });

  return res.data?.data;
};

// 是否显示用户的头像
export const getConfigRealInfo = async () => {
  const res = await http.get(`/system/config/configKey/realInfo`);
  return res.data?.data;
};
