import { DotLoading } from "antd-mobile";
import { Suspense } from "react";

const PageLoader = (props: any) => {
  const { children } = props;

  return (
    <Suspense
      fallback={
        <div
          className="flex justify-center items-center"
          style={{
            height: "100vh",
          }}
        >
          <DotLoading color="primary" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export { PageLoader };
