import React, { useState, useEffect } from "react";
import { useOutlet, useLocation } from "react-router-dom";

const Offscreen = (React as any).unstable_Offscreen;

const whitelist = ["/main", "/main/process"];

const K = () => {
  const [outlets, setOutlets] = useState<any>(new Map());
  const location = useLocation();
  const outlet = useOutlet();

  useEffect(() => {
    const key = location.pathname;
    if (whitelist.includes(key) && !outlets.has(key)) {
      setOutlets((prev: any) => {
        const next = new Map(prev);
        next.set(key, outlet);
        return next;
      });
    }
  }, [location.pathname, outlet]);

  return (
    <>
      {[...outlets.keys()].map((x) => {
        return (
          <Offscreen
            key={x}
            mode={location.pathname === x ? "visible" : "hidden"}
          >
            {outlets.get(x)}
          </Offscreen>
        );
      })}
      {whitelist.includes(location.pathname) ? null : outlet}
    </>
  );
};

export { K };
