import { getConfigRealInfo } from "@/api/system";
import { makeAutoObservable } from "mobx";

const globalStore = makeAutoObservable({
  scene: "",
  coverFlag: false,

  SET_SCENE(scene: string) {
    this.scene = scene;
  },
  async FETCH_COVER_FLAG() {
    const flag = await getConfigRealInfo();

    this.coverFlag = flag === "y";
  },
});

export { globalStore };
