import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const main = async () => {
  await import("@/styles/index.scss");

  if (import.meta.env.MODE?.includes("mask")) {
    await Promise.all([import("@/styles/variable-red.scss")]);
  } else {
    await Promise.all([import("@/styles/variable.scss")]);
  }

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>,
  );
};

main();
