import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { config } from "@/config";
import { userStore } from "@/store/userStore";

const http = axios.create({
  baseURL: config.API,
  withCredentials: false,
});

export class ResponseError extends Error {
  response: AxiosResponse;

  constructor(message: string, response: AxiosResponse) {
    super(message);
    this.response = response;
  }
}

http.interceptors.request.use(
  function (config) {
    const headers = config.headers;
    const token = userStore.token;
    const tenant = userStore.tenant;

    if (headers && !("Authorization" in headers) && token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (tenant) {
      headers["tenant"] = tenant;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  function (response) {
    if (response.headers["content-type"]?.includes("application/json")) {
      const code = response.data.code;

      if (code !== 0) {
        const error = new ResponseError(
          `${response.data.code}:${response.data.msg}`,
          response,
        );
        return Promise.reject(error);
      }
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const promise = http({ ...config, ...options });

  return promise;
};

export default http;
export { http, customInstance };
