import React, { lazy } from "react";
import {
  createBrowserRouter,
  redirect,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { K } from "./K";
import { userStore } from "@/store/userStore";
import { PageLoader } from "@/components/pageLoader";
import * as Sentry from "@sentry/react";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const Process = lazy(() => import("@/pages/Process"));
const Home = lazy(() => import("@/pages/Home"));
const ProcessDetail = lazy(() => import("@/pages/ProcessDetail"));
const EpicDetail = lazy(() => import("@/pages/EpicDetail"));
const ProcessHandle = lazy(() => import("@/pages/ProcessHandle"));
const ProcessMessage = lazy(() => import("@/pages/ProcessMessage"));
const Setting = lazy(() => import("@/pages/Setting"));
const SettingAuthorizationAdd = lazy(() => import("@/pages/SettingAuthorizationAdd"));

const ProcessHandleErrorBoundary = lazy(
  () => import("@/pages/ProcessHandle/errorBoundary"),
);

const router = sentryCreateBrowserRouter([
  {
    path: "/auth",
    lazy: () => import("@/pages/Auth"),
  },
  {
    path: "/",
    element: <K />,
    loader: (e) => {
      if (!userStore.token) {
        const url = new URL(e.request.url);
        return redirect(
          `/auth?redirect=${encodeURIComponent(
            `${url.pathname}${url.search}`,
          )}`,
        );
      } else {
        return null;
      }
    },
    children: [
      {
        index: true,
        loader: () => {
          return redirect("/main");
        },
      },
      {
        path: "main",
        lazy: () => import("@/layout/index"),
        children: [
          {
            index: true,
            element: (
              <PageLoader>
                <Process />
              </PageLoader>
            ),
          },
        ],
      },
      {
        path: "processDetail",
        loader: async (e) => {
          const { loader } = await import("@/pages/ProcessDetail/loader");
          return loader(e);
        },
        element: (
          <PageLoader>
            <ProcessDetail />
          </PageLoader>
        ),
        errorElement: (
          <PageLoader>
            <ProcessHandleErrorBoundary />
          </PageLoader>
        ),
      },
      {
        path: "epicDetail",
        loader: async (e) => {
          const { loader } = await import("@/pages/EpicDetail/loader");
          return loader(e);
        },
        element: (
          <PageLoader>
            <EpicDetail />
          </PageLoader>
        ),
        errorElement: (
          <PageLoader>
            <ProcessHandleErrorBoundary />
          </PageLoader>
        ),
      },
      {
        path: "processHandle",
        loader: async (e) => {
          const { loader } = await import("@/pages/ProcessHandle/loader");
          return loader(e);
        },
        element: (
          <PageLoader>
            <ProcessHandle />
          </PageLoader>
        ),
        errorElement: (
          <PageLoader>
            <ProcessHandleErrorBoundary />
          </PageLoader>
        ),
      },
      {
        path: "processMessage",
        loader: async (e) => {
          const { loader } = await import("@/pages/ProcessMessage/loader");
          return loader(e);
        },
        element: (
          <PageLoader>
            <ProcessMessage />
          </PageLoader>
        ),
        errorElement: (
          <PageLoader>
            <ProcessHandleErrorBoundary />
          </PageLoader>
        ),
      },
      {
        path: "setting",
        lazy: () => import("@/layout/index"),
        children: [
          {
            index: true,
            element: (
              <PageLoader>
                <Setting />
              </PageLoader>
            ),
          },
        ],
      },
      {
        path: "settingAuthorizationAdd",
        loader: async (e) => {
          const { loader } = await import("@/pages/SettingAuthorizationAdd/loader");
          return loader(e);
        },
        element: (
          <PageLoader>
            <SettingAuthorizationAdd />
          </PageLoader>
        ),
        errorElement: (
          <PageLoader>
            <ProcessHandleErrorBoundary />
          </PageLoader>
        ),
      },
    ],
  },
]);

export { router };
